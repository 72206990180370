import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"
import questionsIcon from "../assets/images/questions-color-icon.png"
import wthlIcon from "../assets/images/vidplaybutton.png"

const HomeCallouts = () => {
  return (
    <Container
      css={css`
        h2 {
          color: #69696a;
          font-size: 30px;
          font-weight: bold;
          line-height: 34px;
        }
        a {
          &:hover {
            text-decoration: none;
          }
        }
        p {
          font-size: 18px;
          line-height: 25px;
          color: #8C8D91;
        }
        ${[mq[2]]} {
          h2 {
            font-size: 18px;
            line-height: 21px;
          }
        }
      `}
    >
      <Row>
        <Col
          css={css`
            padding: 30px 14px 15px;
            border-bottom: 1px solid #D8D8D8;
          `}>
          <p>
            Hodgkin Hub is for people diagnosed with classical Hodgkin lymphoma (also known as Hodgkin's lymphoma or Hodgkin's disease) and their caregivers. Hodgkin Hub provides first-hand stories from Hodgkin lymphoma patients about how they navigated their treatment experiences and became their own advocates.
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          lg={6}
          css={css`
            padding: 30px 15px;
       
          `}
        >
          <a
            data-testid="home_link_questionsCallout"
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100%;
              padding: 0 15px;
              ${[mq[2]]} {
                padding-left: 25px;
              }
            `}
            href="/hodgkin-lymphoma-treatment-discussion-guide"
            target="_self"
          // rel="noopener noreferrer"
          >
            <h2>
              HODGKIN LYMPHOMA
              <br /> TREATMENT DISCUSSION GUIDE
            </h2>
            <img
              loading='lazy'
              css={css`
                width: 95px;
                ${[mq[2]]} {
                  width: 80px;
                }
              `}
              alt="questions color icon"
              src={questionsIcon}
            />
          </a>
        </Col>
        <Col
          lg={6}
          css={css`
            ${[mq[2]]} {
              border-top: none;
            }
            ${[mq[1]]} {
              border-top: 1px solid #D8D8D8;
            }
          `}
        >
          <a href="/videos" data-testid="home_link_videosCallout" css={css``}>
            <aside
              css={css`
                padding: 40px 15px;
                display: flex;
                border-top: none;
                border-left: 1px solid #D8D8D8;
                ${[mq[2]]} {
                  border-left: none;
                  border-top: 1px solid #D8D8D8;
                }
                ${[mq[1]]} {
                  border-top: none;
                }
              `}
            >
              <section css={css`
                  margin-left: 10px;
                  `}
              >
                <h2>WATCH VIDEOS</h2>
                <p
                  css={css`
                    ${[mq[2]]} {
                      font-size: 16px;
                      line-height: 20px;
                      margin: 0;
                    }
                  `}
                >
                  For more information on Hodgkin lymphoma and to learn how other young adults managed their diagnosis, treatment decisions and more.
                </p>
              </section>
              <section
                css={css`
                  display: block;
                  ${[mq[2]]} {
                    display: flex;
                    align-items: center;
                  }
                `}
              >
                <img
                  loading='lazy'
                  css={css`
                    margin-top: 25px;
                    margin-left: 45px;
                    width: 84px;
                    ${[mq[2]]} {
                      margin-left: 20px;
                    }
                  `}
                  alt="Play Video Button"
                  src={wthlIcon}
                />
              </section>
            </aside>
          </a>
        </Col>
      </Row>
    </Container>
  )
}

// HomeCallouts.propTypes = {

// }

export default HomeCallouts
