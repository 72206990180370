import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"
import playButton from "../assets/images/play-button.png"

const VideoHighlight = ({ bgImage, title, urlSlug, isVideoHighlight }) => {
  return (
    // <a href={`${isVideoHighlight ? "videoHighlight" : "carousel"
    //   }_link_${urlSlug.substring(1)}`}
    //   to={urlSlug}
    // >
      <a href={`${urlSlug.substring(1)}`}
      to={urlSlug}
    >
      <section
        css={css`
          position: relative;
        `}
      >
        <Img
          css={css`
            width: 100%;
            ${[mq[2]]} {
              height: 200px;
            }
          `}
          alt={`${title} video`}
          fluid={bgImage}
        />
        {/* <img
        css={css`
          width: 100%;
        `}
        alt={`${title} video`}
        src={bgImage}
      /> */}
        <div
          css={css`
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            ${[mq[3]]} {
              margin: 30px 15px 10px 15px;
            }
          `}
        >
          <img
            loading='lazy'
            css={css`
              width: 90px;
              margin-bottom: 10px;
              ${[mq[2]]} {
                width: 50px;
                margin-bottom: 0;
              }
            `}
            alt="play button"
            src={playButton}
          />
          <hgroup>
            <h3
              css={css`
                color: white;
                font-size: 26px;
                font-weight: normal;
                line-height: normal;
                text-shadow: 0 2px 7px black;
                margin: 0;
                text-align: right;
                ${[mq[2]]} {
                  font-size: 17px;
                  line-height: 20px;
                }
              `}
              style={
                title === 'Hodgkin Lymphoma Treatment Questions' ? { maxWidth: '300px' } : { maxWidth: '412px' }
              }
            >
              {title}
            </h3>
          </hgroup>
        </div>
      </section>
    </a>
  )
}

VideoHighlight.propTypes = {
  urlSlug: PropTypes.string.isRequired,
  bgImage: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default VideoHighlight
