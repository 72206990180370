import { useStaticQuery, graphql } from "gatsby"

// Get data for Hero Carousel
// returns array of at least 3 Video Highlights
export const useHeroCarousel = () => {
  const { contentfulHomePage } = useStaticQuery(
    graphql`
      query {
        contentfulHomePage(title: { eq: "Home Page" }) {
          heroCarousel {
            urlSlug
            title
            videoHighlight {
              fluid(quality: 100, maxWidth: 1110) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    `
  )
  return contentfulHomePage.heroCarousel
}
