import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import basicsDesktop from "../assets/images/basics-desktop-update.png"
import bgLines from "../assets/images/bg-lines.png"

const Basics = () => {
  return (
    <Container>
      <Row>
        <Col>
          <a href='/about'>
            <article
              css={css`
                background: url(${bgLines}),
                  linear-gradient(180deg, #02d7f9 0%, #01aef1 100%);
                padding: 20px 30px;  
              `}
            >
              {/* temporary switched to basicsDesktop from basicsMobile */}
              <picture>
                <source media="(min-width: 992px)" srcSet={basicsDesktop} />
                <source media="(max-width: 991px)" srcSet={basicsDesktop} />
                <img
                  loading='lazy'
                  css={css`
                    width: 100%;
                  `}
                  src={basicsDesktop}
                  alt="Hodgkins Lymphoma - the basics"
                />
              </picture>
            </article>
          </a>
        </Col>
      </Row>
    </Container>
  )
}

// Basics.propTypes = {

// }

export default Basics
