import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import PropTypes from "prop-types"

import { mq } from "../utils/media-queries"
import { useHeroCarousel } from "../hooks/use-hero-carousel"
import VideoHighlight from "./VideoHighlight"
import ErrorBoundary from "./ErrorBoundary"

const HeroCarousel = ({ content }) => {
  // settings for carousel
  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    // The css from slick-carousel is needed. To avoid adding
    // slick-carousel into the project (therefore adding another
    // dependency), the css was pulled from the project and included
    // below.
    <article
      className="hero-carousel"
      css={css`
        /* slick.css
              https://github.com/kenwheeler/slick/blob/master/slick/slick.css
              */
        /* Slider */
        .slick-slider {
          position: relative;

          display: block;
          box-sizing: border-box;

          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          -webkit-touch-callout: none;
          -khtml-user-select: none;
          -ms-touch-action: pan-y;
          touch-action: pan-y;
          -webkit-tap-highlight-color: transparent;
        }

        .slick-list {
          position: relative;

          display: block;
          overflow: hidden;

          margin: 0;
          padding: 0;
        }
        .slick-list:focus {
          outline: none;
        }
        .slick-list.dragging {
          cursor: pointer;
          cursor: hand;
        }

        .slick-slider .slick-track,
        .slick-slider .slick-list {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }

        .slick-track {
          position: relative;
          top: 0;
          left: 0;

          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        .slick-track:before,
        .slick-track:after {
          display: table;

          content: "";
        }
        .slick-track:after {
          clear: both;
        }
        .slick-loading .slick-track {
          visibility: hidden;
        }

        .slick-slide {
          display: none;
          float: left;

          height: 100%;
          min-height: 1px;
        }
        [dir="rtl"] .slick-slide {
          float: right;
        }
        .slick-slide img {
          display: block;
        }
        .slick-slide.slick-loading img {
          display: none;
        }
        .slick-slide.dragging img {
          pointer-events: none;
        }
        .slick-initialized .slick-slide {
          display: block;
        }
        .slick-loading .slick-slide {
          visibility: hidden;
        }
        .slick-vertical .slick-slide {
          display: block;

          height: auto;

          border: 1px solid transparent;
        }
        .slick-arrow.slick-hidden {
          display: none;
        }

        /* slick-them.css 
              https://github.com/kenwheeler/slick/blob/master/slick/slick-theme.css
              */
        /* Slider */
        .slick-loading .slick-list {
          background: #fff url("./ajax-loader.gif") center center no-repeat;
        }

        /* Icons */
        /*@font-face {
          font-family: "slick";
          font-weight: normal;
          font-style: normal;

          src: url("./fonts/slick.eot");
          src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"),
            url("./fonts/slick.woff") format("woff"),
            url("./fonts/slick.ttf") format("truetype"),
            url("./fonts/slick.svg#slick") format("svg");
        }*/
        /* Arrows */
        .slick-prev,
        .slick-next {
          font-size: 0;
          line-height: 0;

          position: absolute;
          top: 50%;

          display: block;

          width: 20px;
          height: 20px;
          padding: 0;
          -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);

          cursor: pointer;

          color: transparent;
          border: none;
          outline: none;
          background: transparent;
        }
        .slick-prev:hover,
        .slick-prev:focus,
        .slick-next:hover,
        .slick-next:focus {
          color: transparent;
          outline: none;
          background: transparent;
        }
        .slick-prev:hover:before,
        .slick-prev:focus:before,
        .slick-next:hover:before,
        .slick-next:focus:before {
          opacity: 1;
        }
        .slick-prev.slick-disabled:before,
        .slick-next.slick-disabled:before {
          opacity: 0.25;
        }

        .slick-prev:before,
        .slick-next:before {
          /*font-family: "slick";*/
          font-size: 20px;
          line-height: 1;

          opacity: 0.75;
          color: white;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        .slick-prev {
          left: -25px;
        }
        [dir="rtl"] .slick-prev {
          right: -25px;
          left: auto;
        }
        .slick-prev:before {
          content: "←";
        }
        [dir="rtl"] .slick-prev:before {
          content: "→";
        }

        .slick-next {
          right: -25px;
        }
        [dir="rtl"] .slick-next {
          right: auto;
          left: -25px;
        }
        .slick-next:before {
          content: "→";
        }
        [dir="rtl"] .slick-next:before {
          content: "←";
        }

        /* Dots */
        .slick-dotted.slick-slider {
          margin-bottom: 30px;
        }

        .slick-dots {
          position: absolute;
          bottom: 70px;

          display: block;

          width: 100%;
          padding: 0;
          margin: 0;

          list-style: none;

          text-align: center;
          ${[mq[2]]} {
            bottom: 5px;
          }
        }
        .slick-dots li {
          position: relative;

          display: inline-block;

          width: 30px;
          height: 20px;
          margin: 0 5px;
          padding: 0;

          cursor: pointer;
        }
        .slick-dots li button {
          font-size: 0;
          line-height: 0;

          display: block;

          width: 20px;
          height: 20px;
          padding: 5px;

          cursor: pointer;

          color: transparent;
          border: 0;
          outline: none;
          background: transparent;
        }
        .slick-dots li button:hover,
        .slick-dots li button:focus {
          outline: none;
        }
        .slick-dots li button:hover:before {
          opacity: 1;
          color: white;
        }
        .slick-dots li button:focus:before {
          /* opacity: 0;
          color: white; */
          @media screen and (max-width: 991px) {
            opacity: 0.49;
            color: black;
          }
        }
        .slick-dots li button:before {
          /*font-family: "slick";*/
          font-size: 55px;
          line-height: 20px;

          position: absolute;
          top: 0;
          left: 0;

          width: 20px;
          height: 20px;

          content: "•";
          text-align: center;

          opacity: 0.49;
          color: black;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        .slick-dots li.slick-active button:before {
          opacity: 0.75;
          color: white;
        }
      `}
    >
      <Slider {...settings}
        css={css`
          ${[mq[2]]} {
            z-index: 0;
          }
        `}
      >
        {content.map(({ videoHighlight, title, urlSlug }) => (
          <VideoHighlight
            urlSlug={urlSlug}
            bgImage={videoHighlight.fluid}
            title={title}
            key={`slider-${title}`}
          />
        ))}
      </Slider>
    </article>
  )
}

HeroCarousel.propTypes = {
  content: PropTypes.array.isRequired,
}

export default () => {
  // grab array of Video Highlight data using useHeroCarousel hook
  const videosArray = useHeroCarousel()

  return (
    <Container>
      <Row>
        <Col>
          <ErrorBoundary>
            <HeroCarousel content={videosArray} />
          </ErrorBoundary>
        </Col>
      </Row>
    </Container>
  )
}
