import { useStaticQuery, graphql } from "gatsby"

// Get data for Video Highlight section on Homepage
export const useVideoHighlight = () => {
  const { contentfulHomePage } = useStaticQuery(
    graphql`
      query {
        contentfulHomePage(title: { eq: "Home Page" }) {
          videoHighlight {
            videoHighlight {
              fluid(quality: 100, maxWidth: 1110) {
                ...GatsbyContentfulFluid
              }
            }
            title
            urlSlug
          }
        }
      }
    `
  )
  return contentfulHomePage.videoHighlight
}
