import React from 'react'

import Layout from '../components/Layout'
import HeroCarousel from '../components/HeroCarousel'
import HomeCallouts from '../components/HomeCallouts'
import HomeInfographic from '../components/HomeInfographic'
import Basics from '../components/Basics'
import VideoHighlightSection from '../components/VideoHighlightSection'
import References from '../components/about/References'


export default () => {
  return (
    <Layout
      title='Hodgkin Lymphoma Patient Stories and Resources | Hodgkin Hub'
      description='Hodgkin Hub is for those diagnosed with classical Hodgkin lymphoma and their caregivers. Hodgkin Hub provides first-hand stories from Hodgkin lymphoma patients.'
      canonicalURL='https://hodgkinhub.com/'
      OGTitle='Hodgkin Lymphoma Patient Stories and Resources | Hodgkin Hub'
      OGDescription='Hodgkin Hub is for those diagnosed with classical Hodgkin lymphoma and their caregivers. Hodgkin Hub provides first-hand stories from Hodgkin lymphoma patients.'
      OGImage="https://hodgkinhub.com/static/header-logo-02c93b151e6a0b1d545c321f517bafd0.png"
      OGUrl='https://hodgkinhub.com/'
      OGType='Website'
    >
      <HeroCarousel />
      <HomeCallouts />
      <Basics />
      <HomeInfographic />
      <VideoHighlightSection />
      <References isHome />
    </Layout>
  )
}
