import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { useVideoHighlight } from "../hooks/use-video-highlight"
import VideoHighlight from "../components/VideoHighlight"

const VideoHighlightSection = () => {
  const { videoHighlight, title, urlSlug } = useVideoHighlight()

  return (
    <Container
      css={css`
        margin-top: 45px;
      `}
    >
      <Row>
        <Col>
          <VideoHighlight
            bgImage={videoHighlight.fluid}
            title={title}
            urlSlug={urlSlug}
            isVideoHighlight
          />
        </Col>
      </Row>
    </Container>
  )
}

export default VideoHighlightSection
